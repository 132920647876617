@import "../../../../../../styles/main";

.title {
  @include Footnote_description;
  margin-bottom: 10px;
  color: #000;
}

.customRadio {
  :global {
    .ant-radio {
      .ant-radio-inner {
        border-color: #494F65;
        width: 16px;
        height: 16px;
      }
      &.ant-radio-checked {
        .ant-radio-inner {
          border-color:  #C0180C;
          background: #fff;
        }
        .ant-radio-inner::after {
          background-color:  #C0180C;
        }
      }
    }
  }
}