@import '../../../../styles/main';

.main {
  padding: 32px;
}

.top {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.block {
  margin-top: 12px;
  border: 1px solid #0000000F;
  border-radius: 16px;
  padding: 16px;
  &_radio {
    & span {
      font-weight: 700;
    }
  }
  &_text {
    margin-top: 10px;
    @include Body_regular;
  }
}

.title {
  margin-top: -2px;
  @include H5_medium;
}

.text {
  margin-top: 8px;
  @include Body_regular;
  & span {
    color: #1890FF;
    font-weight: 700;
  }
}

.footer {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.okButton {
  @include Body_regular;
  height: 32px;
}