@import 'src/styles/main';

.passwordBlock {
  display: flex;
  align-items: center;
  gap: 16px;
  :global {
    .ant-form-item-row {
      margin-bottom: 0;
    }
  }
}

.field {
  flex: 1 1 70%;
}
.text {
  flex: 1 1 30%;
  text-align: right;
  color: #096DD9;
  @include Body_regular;
  cursor: pointer;
}