.search {
  width: 100%;
  :global {
    input {
      border: 1px solid #D9D9D9;
      border-radius: 2px;
    }
    .ant-btn {
      background-color: transparent;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      height: 31.5px;
    }
  }
}
