@import 'src/styles/main';

.footer {
  padding: 10px;
  border-top: 1px solid  #F0F0F0;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.okButton {
  @include Main_button;
  @include Body_regular;
  height: 32px;
}