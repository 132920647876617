.wrapper {
  margin-top: 42px;
  max-width: 554px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.text {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #00000073;
}

.button {
  margin-top: 24px;
}