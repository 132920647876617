@import 'src/styles/main';

.wrapper {
  padding: 0 24px;
  :global {
    .ant-table {
      min-height: calc(100vh - 145px);
    }
  }
}

.item {
  :global {
    .ant-form-item-row {
      display: block;
      border: none;
    }
    .ant-input {
      height: 40px;
      border-radius: 2px;
      padding: 8px 12px;
      border: none;
      box-shadow: none;
      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 2px;
      height: 40px;
      padding: 0 12px 0 12px;
      border: none;
      box-shadow: none;
      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }
}