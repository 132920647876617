@import 'src/styles/main';

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  &_title {
    @include H4_medium;
    color: #000
  }
  &_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  &_button {
    @include Main_button;
    @include Body_regular;
    height: 30px;
    width: 120px;
  }
}