@import 'src/styles/main';

.status {
  width: 265px;
  &_title {
    @include Footnote_description;
  }
  &_switch {
    margin-top: 18px;
    display: flex;
    align-items: center;
    gap: 8px;
    &_label {
      @include Body_regular;
      color: #939393;
    }
  }
}
