@import 'src/styles/main';

.filters {
  margin-top: 24px;
  width: 100%;
  background-color: #F0F2F5;
  padding: 8px 12px;
  height: 38px;
  @include Body_regular;
  &_inner {
    display: flex;
    gap: 8px;
    &_list {
      display: flex;
      gap: 8px;
      &_item {
        border: 1px solid #D9D9D9;
        @include Footnote_description;
        padding: 1px 8px;
        background-color: #fff;
        border-radius: 2px;
        display: flex;
        align-items: center;
        & svg {
          cursor: pointer;
          margin-top: 0px;
          margin-left: 5px;
        }
      }
    }
  }
}