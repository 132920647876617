@import 'src/styles/main';

.toolbar {
  padding: 24px;
  &_top {
    display: flex;
    justify-content: space-between;
    &_title {
      @include H4_medium;
      color: #000
    }
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 42px;
    }
  }
}