@import 'colors';

// Typography
@mixin Body_regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

@mixin Body_medium {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

@mixin H3_regular {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

@mixin H4_medium {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

@mixin H5_regular {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin H5_medium {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin Footnote_description {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

// Buttons
@mixin Main_button {
  @include H5_regular;
  border-radius: 8px;
  background: $light_primary_brand_800;
  &:hover {
    background: $light_primary_brand_800 !important;
    opacity: 0.8;
  }
}