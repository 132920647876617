.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100vh;
}

.main {
  flex: 1 1 100%;
  max-width: calc(100vw - 212px);
  transition: max-width .3s;
  &_short {
    max-width: calc(100vw - 50px);
    transition: max-width .3s;
  }
}