@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Black'),
            url('Roboto/Roboto-Black.ttf') format('truetype');
    font-display: swap;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Black Italic'),
            url('Roboto/Roboto-BlackItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Bold'),
            url('Roboto/Roboto-Bold.ttf') format('truetype');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Bold Italic'),
            url('Roboto/Roboto-BoldItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Italic'),
            url('Roboto/Roboto-Italic.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Light'),
            url('Roboto/Roboto-Light.ttf') format('truetype');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Light Italic'),
            url('Roboto/Roboto-LightItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Medium'),
            url('Roboto/Roboto-Medium.ttf') format('truetype');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Medium Italic'),
            url('Roboto/Roboto-MediumItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Regular'),
            url('Roboto/Roboto-Regular.ttf') format('truetype');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Thin'),
            url('Roboto/Roboto-Thin.ttf') format('truetype');
    font-display: swap;
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src:
            local('Roboto Thin Italic'),
            url('Roboto/Roboto-ThinItalic.ttf') format('truetype');
    font-display: swap;
    font-weight: 100;
    font-style: italic;
}


