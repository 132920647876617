@import '../../../styles/main';

.form {
  margin-top: 51px;
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.formItem {
  margin-top: 98px;
  &_button {
    @include Main_button;
    width: 200px;
    height: 38px;
  }
}