@import 'src/styles/main';

.filter {
  position: relative;
  width: 100%;

  &_dropdown {
    color: $primary_6;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}