@import 'src/styles/main';

.imageBlock {
  display: flex;
  align-items: center;
  gap: 24px;
  &_avatar {
    & img {
      height: 72px;
      width: 72px;
      border-radius: 50%;
    }
  }
  &_circle {
    width: 72px;
    height: 72px;
    box-shadow: 0 2px 8px 0 #00000026;
    border-radius: 50%;
  }
  &_text {
    @include Footnote_description;
    color: #00000073;
    margin-top: 8px;
  }
  &_input {
    display: none !important;
  }
}
