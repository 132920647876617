@import 'src/styles/main';

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 24px;
  box-shadow: 0 4px 20px 0 #1F242A1A;
  display: flex;
  overflow: hidden;
}

.main {
  padding: 42px;
  width: 484px;
  &_subtext {
    color: #00000073;
    @include  Body_regular;
    margin-bottom: 4px;
  }
  &_title {
    @include  H3_regular;
    color: #000;
  }
  &_text {
    margin-top: 4px;
    color: #00000073;
    @include  Body_regular;
  }
}

.image {
  background-image: url("../../assets/images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2E2F37;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 42px;
}