@import './assets/fonts/fonts.css';

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*Scrollbar*/
/* Style for Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #00000073;
  border-radius: 10px;
  border: 1px solid #727070;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

