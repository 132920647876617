@import 'src/styles/main';

.sidebar {
  background: #2E2F37;
  height: 100%;
  flex: 0 0 212px;
  position: relative;
  min-width: 212px;
  transition: all .3s;
  overflow: hidden;
  &_short {
    flex: 0 0 0;
    min-width: 50px;
    transition: all .3s;
  }
  &_top {
    padding: 24px 16px;
  }
  &_list {
    margin-top: 16px;
    &_item {
      padding: 9px 16px;
      @include Body_regular;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 40px;
      margin-bottom: 8px;
      cursor: pointer;
      white-space: pre;
      & svg {
        min-width: 14px;
      }
      &_active {
        background: #3A3B42;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_profile {
    position: absolute;
    bottom: 50px;
    padding: 13px 16px 13px 16px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &_left {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      white-space: pre;
    }
    &_right {
      cursor: pointer;
    }
  }
  &_collapse {
    position: absolute;
    height: 41px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    bottom: 0;
    background-color: #3A3B42;
    cursor: pointer;
  }
}