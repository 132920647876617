@import '../../../styles/main';

.form {
  margin-top: 51px;
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.formCheckboxWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_link {
    @include Body_medium;
    color: #00000073;
    cursor: pointer;
  }
}

.formCheckbox {
  :global {
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border-color: $light_primary_brand_800 !important;
    }
    .ant-checkbox-inner {
      background-color: #fff;
      border-color: $neutral_5;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $light_primary_brand_800 !important;
      border-color: $light_primary_brand_800 !important;
    }
  }
}

.formItem {
  margin-top: 51px;
  &_button {
    @include Main_button;
    width: 200px;
    height: 38px;
  }
}