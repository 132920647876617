@import 'src/styles/main';

.inner {
  top: 65px;
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0 9px 28px 8px #0000000D;
  background-color: #fff;
  min-height: 460px;
  position: absolute;
  min-width: 256px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  z-index: 5;
  &_visible {
    top: 35px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
  }
  &_sliderItem {
    margin-top: 24px;
    margin-bottom: 16px;
  }
  &_item {
    :global {
      .ant-select-selector {
        height: 32px !important;
      }
      .ant-form-item-row {
        display: block;
        margin-bottom: 0;
      }
      .ant-form-item {
        margin-bottom: 16px;
      }
    }
  }
  &_buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    &_okButton {
      @include Main_button;
      @include Body_regular;
      height: 32px;
    }
  }
}