@import 'src/styles/main';

.item {
  :global {
    .ant-input-number-handler-wrap {
      display: none;
    }
    .ant-input-number {
      border-radius: 2px;
      padding-top: 4px;
      width: 100%;
      height: 40px;
    }
    .ant-form-item-row {
      display: block;
      margin-bottom: 38px;
    }
    .ant-form-item-label {
      text-align: left;
    }
    .ant-form-item-label > label {
      @include Footnote_description;
      color: #000;
      &::after {
        display: none;
      }
    }
    .ant-input {
      height: 40px;
      border-radius: 2px;
      padding: 8px 12px;
    }
    .ant-input-affix-wrapper {
      border-radius: 2px;
      height: 40px;
      padding: 0 12px 0 12px;
    }
  }
}