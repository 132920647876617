@import 'src/styles/main';

.main {
  padding: 24px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  :global {
    .ant-form-item-row {
      width: 265px;
      margin-bottom: 0;
    }
    .ant-select-selector {
      width: 265px;
    }
  }
}
