.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.image {
  & svg {
    box-shadow: 0 2px 8px 0 #00000026;
    border-radius: 50%;
    width: 72px;
    height: 72px;
  }
  & img {
    height: 72px;
    width: 72px;
    border-radius: 50%;
  }
}

.input {
  display: none;
}