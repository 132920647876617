.wrapper {
  margin-top: 42px;
}

.item {
  display: flex;
  align-items: center;
  gap: 24px;
  :global {
    .ant-form-item-row {
      width: 400px;
      margin-bottom: 0;
    }
  }
}

.button {
  margin-top: 5px;
  height: 40px;
  & button {
    height: 100%;
    width: 70px;
  }
}