@import 'src/styles/main';

.wrapper {
  padding: 0 24px;
  :global {
    .ant-table {
      min-height: calc(100vh - 145px);
    }
  }
  &_short {
    :global {
      .ant-table {
        min-height: calc(100vh - 207px);
      }
    }
  }
}