@import 'src/styles/main';

.main {
  padding: 32px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.title {
  margin-top: -2px;
  @include H5_medium;
}

.text {
  margin-top: 8px;
  @include Body_regular;
  & span {
    color: #1890FF;
    font-weight: 700;
  }
}

.footer {
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.okButton {
  @include Main_button;
  @include Body_regular;
  height: 32px;
}