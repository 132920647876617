@import '../../../styles/main';

.form {
  margin-top: 51px;
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.formButtons {
  margin-top: 209px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_link {
    @include Body_medium;
    color: #00000073;
    &:hover {
      color: #00000073;
    }
  }
  &_button {
    @include Main_button;
    width: 200px;
    height: 38px;
  }
}