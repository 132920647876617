@import 'src/styles/main';

.wrapper {
  padding: 0 24px;
  :global {
    .ant-table {
      min-height: calc(100vh - 145px);
    }
  }
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.toggleLabel {
  color: #939393;
}