@import 'src/styles/main';

.label {
  color: #000;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

.dropdownButton {
  background-color: transparent;
  border-radius: 4px;
  padding: 8px 11px;
  @include Body_regular;
  width: 100%;
  color: #00000073;
  display: flex;
  justify-content: space-between;
  &:hover {
    color: #00000073 !important;
  }
  &_visible {
    border: 1px solid #595959 !important;
    box-shadow: 0 2px 0 0 #00000004 !important;
  }
}

.dropdownContent {
  padding: 5px 10px 32px 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px #0000001F;
}

.slider {
  margin-bottom: 15px;
}

.ranges {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &_label {
    color: #000;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }
}

.inputNumber {
  margin-top: 10px;
}
