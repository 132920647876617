@import 'src/styles/main';

.item {
  &_selected {
    :global {
      .ant-select-selection-search {
        padding-left: 30px;
      }
    }
  }
  :global {
    .ant-select-selector {
      border-radius: 2px !important;
      height: 40px !important;
      &:hover,
      &:active,
      &:focus {
        border-color: #40a9ff !important;
      }
    }
    .ant-select-single {
      height: unset;
    }
    .ant-select-arrow {
      color: #8c8c8c;
    }
    .ant-form-item-row {
      display: block;
      margin-bottom: 38px;
    }
    .ant-form-item-label {
      text-align: left;
    }
    .ant-select-selection-placeholder {
      @include Body_regular;
      color: #00000073;
    }
    .ant-form-item-label > label {
      @include Footnote_description;
      color: #000;
      &::after {
        display: none;
      }
    }
  }
}